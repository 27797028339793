<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          label-width="70px"
          :model="formInline"
          class="demo-form-inline"
          algin="left"
          ref="formData"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Role_Name')" prop="roleName">
                <el-input v-model.trim="formInline.roleName"></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="search el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button plain icon="clear el-icon-delete" @click="clearData">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44">
            <div class="col_left">
              <router-link :to="{ path: '/roleManageAE' }">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  v-if="$route.meta.authority.button.add"
                  >{{ $t('button.addto') }}</el-button
                >
              </router-link>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="80"
            align="center"
            v-if="$route.meta.authority.button.edit || $route.meta.authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="2" v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="size"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roleManage",
  data() {
    return {
      total: 0,
      size: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "bossManagerRoleName",
          label: "角色名称",
        },
        //      , {
        //        prop: 'bossManagerRoleState',
        //        label: '状态',
        //        width: '',
        //        formatter: (row) => {
        //          if (row.bossManagerRoleState == 1) {
        //            return '启用'
        //          } else {
        //            return '停用'
        //          }
        //        }
        //      },
        {
          prop: "description",
          label: "备注",
          width: "",
        },
        {
          prop: "deptName",
          label: "所属机构",
          width: "",
        },
        {
          prop: "useCount",
          label: "使用账号数",
          width: "",
        },
        {
          prop: "updatedTime",
          label: this.$t("list.add_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        // systemType: 1,
        roleName: "",
        page: "1",
        pageSize: "15",
      },
    };
  },
  methods: {
    // 清空数据
    clearData() {
      this.$refs.formData.resetFields();
    },
    // 操作细则
    handleCommand(command, rowData) {
      if (command == 1) {
        this.$router.push({
          path: "/roleManageAE",
          query: {
            bossManagerRoleId: rowData.bossManagerRoleId,
            bossManagerRoleName: rowData.bossManagerRoleName,
          },
        });
      } else if (command == 2) {
        this.deleteRole(rowData);
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    deleteRole(data) {
      this.$confirm("此操作将删除角色, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bossManagerRole/delete", {
              data: { bossManagerRoleId: data.bossManagerRoleId, elecRoleId: data.elecRoleId },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.searchData();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除角色",
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bossManagerRole/list", {
          data: {
            page: this.page,
            size: this.size,
            roleName: this.formInline.roleName,
            deptId: sessionStorage.selfDeptId != 1 ? sessionStorage.selfDeptId : "",
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {
    // this.searchData();
    // console.log(this.$router)
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-button--default {
  margin-right: 10px;
}

.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #ffffff;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3f4a56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
